<template>
  <AdminView>
    <router-link class="back-link" :to="{ name: 'ProgramsList' }"
      >&lt; Retour</router-link
    >

    <h1 class="title-1">Programmes - Messages audio</h1>

    <ProgramAudioFilesForm :audioFiles="audioFiles" />
  </AdminView>
</template>

<script>
import AdminView from "@/components/AdminView"
import ProgramAudioFilesForm from "@/components/Forms/ProgramAudioFilesForm"

export default {
  name: "ProgramAudioFiles",
  components: {
    AdminView,
    ProgramAudioFilesForm
  },
  data() {
    return {
      section1List: [],
      section2List: [],
      section3List: [],
      audioFiles: {
        uniqueMessages: [],
        nextExercise: [],
        series: []
      }
    }
  },
  async created() {
    const response = await this.$store.dispatch("admin/getSoundsProgram")
    this.section1List = response.filter((el) => el.section === 1)
    this.section2List = response.filter((el) => el.section === 2)
    this.section3List = response.filter((el) => el.section === 3)
    this.audioFiles.uniqueMessages = this.section1List
    this.audioFiles.nextExercise = this.section2List
    this.audioFiles.series = this.section3List
  }
}
</script>

<style lang="scss" scoped></style>
