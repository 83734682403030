<template>
  <div class="form">
    <div class="row">
      <div class="column">
        <h2 class="title-2">Message uniques</h2>
        <InputFile
          v-for="(audioFile, i) in audioFiles.uniqueMessages"
          :key="i"
          :title="$t('audioFilesSection1')[`${i}`]"
          :preview="audioFile.sound.url"
          @changeUrl="changeUrl"
          :typeFile="i"
          type="audio"
          v-model="audioFile.file"
        />
      </div>
    </div>

    <div class="row">
      <div class="column">
        <h2 class="title-2">Début d'exercice</h2>

        <InputFile
          v-for="(audioFile, i) in audioFiles.nextExercise"
          :preview="audioFile.sound.url"
          :key="i"
          :title="$t('audioFilesSection2')[`${i}`]"
          @changeUrl="changeUrl"
          :typeFile="audioFiles.uniqueMessages.length + i"
          type="audio"
          v-model="audioFile.file"
        />
      </div>
    </div>

    <div class="row">
      <div class="column">
        <h2 class="title-2">Numéro de séries</h2>

        <InputFile
          :preview="audioFile.sound.url"
          v-for="(audioFile, i) in audioFiles.series"
          :key="i"
          :title="$t('audioFilesSection3')[`${i}`]"
          @changeUrl="changeUrl"
          :typeFile="
            audioFiles.nextExercise.length + audioFiles.uniqueMessages.length + i
          "
          type="audio"
          v-model="audioFile.file"
        />
      </div>
    </div>

    <div class="row buttons-container">
      <Button
        title="Sauvegarder les modifications"
        type="primary"
        @click="saveModifications"
      />
    </div>
  </div>
</template>

<script>
import InputFile from "@/components/Forms/Fields/InputFile"
import Button from "@/components/Button"

export default {
  name: "ProgramAudioFilesForm",
  components: {
    InputFile,
    Button
  },
  props: ["audioFiles"],
  data() {
    return {}
  },
  computed: {
    newAudioFiles: {
      get() {
        return [
          ...this.audioFiles.uniqueMessages,
          ...this.audioFiles.nextExercise,
          ...this.audioFiles.series
        ]
      },
      set(newAudioFiles) {
        return newAudioFiles
      }
    }
  },
  methods: {
    changeUrl(data) {
      const newValues = this.newAudioFiles
      newValues[data.type].sound.url = data.url
      newValues[data.type].sound.id = data.id
      this.newAudioFiles = newValues
    },
    async saveModifications() {
      await this.$store.dispatch("admin/updateSoundsProgram", this.newAudioFiles)
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  border-bottom: 1px solid $light-grey-color;
  padding: 10px 0;
  margin-top: 20px;

  .row {
    div {
      flex: 1;
    }
  }

  .buttons-container {
    justify-content: center;
    margin: 20px 0;

    div {
      flex: 0;
    }
  }
}
</style>
